import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import UserDashboard from "./components/userDashboard"; // Corrected the import name
import UserSideBarContent from "./components/userSideBarContent"; // Corrected the import name
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import "./styles/dashboard.css";
import UserNav from './components/userNav';
import Profile from './components/pages/profile';
import Appointments from './components/Appointments/Appointments';
import PaymentForm from './components/Payments/payment';
import MyBookings from './components/pages/myBookings';
import Page404 from './page404';
import VerifyUI from './components/VerifyUser/verifyUI';
import Login from './components/login/main';
import {BounceLoader} from 'react-spinners'
import BiometricUI from './components/VerifyUser/fingerBiometric';
import { docQr } from './Logics/docQr';
import { Auth0Provider } from '@auth0/auth0-react';
import AppointmentConfirmation from './components/pages/appointmentConfirmation';
function LandingPage(){
 const getFaqs=async ()=>{
  const data=await docQr("Faqs",{
  max:1,
  whereClauses:[
      {
          field:"0",
          operator:"!=",
          value:""
      }
  ]
});
if(data.length !==0){
  const dataEl=data[0];
delete dataEl.docId;
localStorage.setItem('faqs',JSON.stringify(Object.values(dataEl)));
//console.log(Object.values(dataEl));
}
window.location.href='/i/';
 }
  useEffect(()=>{
  getFaqs();
  },[])
  return (
    <div style={{padding:20}}>
<BounceLoader color='var(--color)'/>
      </div>
  )
}


function App() {
const [animationName,setAnimationName]=useState("openMenu");
const [menuOpen,setMenuOpen]=useState(false);
const openMenu=()=>{
setAnimationName("openMenu");
setMenuOpen(true);
}
// Check if the protocol is HTTP and the origin is not localhost
if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
  // Redirect to HTTPS version
  window.location.replace(window.location.href.replace(/^http:/, 'https:'));
}

const closeMenu=()=>{  
setAnimationName("closeMenu");
setTimeout(()=>{
  setMenuOpen(false);
},800)
}
if(window.location.pathname==='/'){
return <LandingPage/>
}

if(!sessionStorage.getItem("ImmintegralUser")){
  return <Login/>
}
const user=JSON.parse(sessionStorage.getItem("ImmintegralUser"));
//console.log(user);
  return (
    
    <Router>
    <div className="App">
      {/* Define routes */}
   {menuOpen && <div className='sidebarMobileContent' onClick={(e)=>{
    const {target}=e;
    if(target.classList.contains('sidebarMobileContent')){
      closeMenu();
    }
   }}>

<div className='content' style={{animationName}}>
  <div  className="sidebar">
<UserSideBarContent closeMenu={closeMenu}/>
</div>
  </div>
      </div>}

      <MDBContainer fluid className='appContainer'>
              <MDBRow style={{padding:0}}>
                {/* Sidebar */}
                <MDBCol md="3" lg="2" className="sidebar">
                  <UserSideBarContent/>
                </MDBCol>
        
                {/* Main content */}
                <MDBCol md="9" lg="10"style={{padding:0}} className="main-content">
              
<UserNav openMenu={()=>openMenu()} user={user}/>
<div className='userNavPlaceholder'></div>
               
        
      
      <Routes>
        <Route path="/" element={<LandingPage/>} /> {/* Updated element prop */}
        <Route path="/Dashboard" element={<UserDashboard/>} /> {/* Updated element prop */}
        <Route path="/Profile" element={<Profile/>} /> {/* Updated element prop */}
        <Route path="/Appointments" element={<Appointments/>} /> {/* Updated element prop */}
        <Route path='/Payment' element={<PaymentForm/>}/>
        <Route path="/MyBookings" element={<MyBookings/>}/>
        {/* <Route path="/EditProfile" element={<EditProfile/>}/> */}
        <Route path="/Verification" element={<VerifyUI/>}/>
        <Route path='/BiometricUI' element={<BiometricUI/>}/>
        <Route path='/Authentication' element={<BiometricUI/>}/>

        <Route path='/AppointmentConfirmation' element={<AppointmentConfirmation/>}/>

        <Route path='/*' element={<Page404/>}/>
        
      </Routes>
      </MDBCol>
              </MDBRow>
            </MDBContainer>

    </div>

  </Router>
  );
  }
export default App;
