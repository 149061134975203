import React, { useEffect, useState } from 'react';
import {MDBBadge, } from 'mdb-react-ui-kit';
import { Skeleton } from '@mui/material';
import { getRandomNumber } from '../utils/funcs.js';
import useUserDetails from '../Hooks/userUserDetails.js';
import { docQr } from '../Logics/docQr';
import FadeInShade from './animations/fadeIn'
import { getTimeAgoString } from '../Logics/date';
export default function Notifications() {
    const [isLoading,setIsLoading]=useState(true);
    const [notifications,setNotifications]=useState([]);
const {user}=useUserDetails();
    useEffect(()=>{
const getNotifications=async ()=>{
    console.log(user.uid);
    setIsLoading(true);
const data=await docQr("Notifications",{
    max:800,
    whereClauses:[
        {
            field:"uid",
            operator:"==",
            value:user.uid
        }
    ]
})
setNotifications(data);
setIsLoading(false);
}
getNotifications();
    },[]);

    return (
        <div style={{width:"100%",margin:"0 auto"}}>
            <div className="Activities_container"  style={{maxWidth:"100%",margin:"0 auto"}}>
                {!isLoading ? notifications.map((e,i)=>(<div key={i} className='Activities_card'><FadeInShade> <div key={i} className="d-flex align-items-center">
                    <img style={{width:60,height:60}} src="/images/bell.jpg" className="card-img-top" alt="Transaction" />

                    <div>
                        <b>{e.title}</b><br/>
                        <span>{e.body}</span>
                     <br/>   <MDBBadge pill className='me-2 text-dark' color='light' light>
        {getTimeAgoString(e.sent_at)}
      </MDBBadge>
                    </div>
                </div></FadeInShade></div>)):<NotificationSkeleton/>}

                    
            {!isLoading && notifications.length === 0 && <div className="flexCenter" style={{ padding: 20 }}>
            <div className='text-center'>
              <img src='/images/noData.png' alt="" /><br />
              <b>No Data Found</b>
            </div>
          </div>}

            </div>


        </div>
    );
}




const NotificationSkeleton = () => {
    return (
      <div style={{ width: "100%", margin: "0 auto" }}>
        <div className="Activities_container" style={{ maxWidth: "100%", margin: "0 auto" }}>
          {[1, 2, 3, 4, 5].map((e, i) => (
            <div className='Activities_card' key={i}>
              <FadeInShade>
                <div className="d-flex align-items-center">
                  <Skeleton variant="circular" width={60} height={60} />
  
                  <div style={{ marginLeft: '1rem' }}>
                    <Skeleton variant="text" width={200} height={20} />
                    <Skeleton variant="text" width={300} height={40} />
                    <Skeleton variant="text" width={150} height={20} />
                  </div>
                </div>
              </FadeInShade>
            </div>
          ))}
        </div>
      </div>
    );
  };
  