import React, { useEffect,useState } from 'react'
import {MDBBtn} from 'mdb-react-ui-kit';
import "./confirmation.css";
import { Margin, usePDF } from "react-to-pdf";
import {useNavigate} from 'react-router-dom';
import { Download } from 'react-feather';
import Barcode from 'react-barcode';
const AppointmentConfirmation=({})=>{

const navigate=useNavigate();
    const { toPDF, targetRef } = usePDF({
        filename:"Immintegral-Appointment-confirmation.pdf",
        page: { margin: Margin.MEDIUM }
      });
const [data,setData]=useState({});

useEffect(()=>{
const storedData=sessionStorage.getItem("AppointmentConfirmationData");
if(storedData){
setData(JSON.parse(storedData));
console.log(JSON.parse(storedData))
}
else{
navigate("/Dashboard");
}
},[]);

    return (
        <div className='confirmationContainer'>
<div style={{
    right:"0",

    bottom:"0",
    margin:'20px',
    position:"fixed",

}}>
    <MDBBtn rounded onClick={()=>{
    console.log("download pdf")
    toPDF()
    }}>Download PDF <Download/> </MDBBtn>
</div>
        <div className='confirmation' ref={targetRef}>

            <h5 className='text-center'>APPOINTMENT CONFIRMATION</h5>


            <div className='sectionItem'>
<header>
Applicant Details
    </header>
<div className='container d-flex'>

<div>
  <li>  Applicant Name : {data?.applicantName}</li>
  <li>  Passport number : {data?.passportNumber}</li>
  <li>  Appointment made by : {data?.appointmentMadeBy}</li>
  <li>  Number of Applicants : {data?.numberOfApplicants}</li>
</div>

<div>
  <li>  Visa Class  : {data?.visaClass}</li>
  <li>  Visa Category : {data?.visaCategory}</li>
  <li>  Visa Priority: {data?.visaPriority}</li>
</div>



</div>



</div>

{/* <header>
CONSULAR APPOINTMENT DETAILS
</header>
<div className='container d-flex'>

<div>
<li>  Date : Thursday January 2024</li>
<li>  Time : 2:23:00 PM</li>
</div>

<div>
<li>  Address : Kaduna Nigeria Nepa Road</li>
</div>



</div>
 */}



<div className='sectionItem'>
<header>
CONSULAR APPOINTMENT DETAILS
</header>
<div className='container d-flex'>

<div>
<li>  Date : {data?.date}</li>
<li>  Time : {data?.time}</li>
</div>

<div>
<li>  Address : {data?.consularAddress} </li>
</div>
</div>

</div>



<div className='sectionItem'>

<header>
DOCUMENT DELIVERY INFORMATION
</header>
<div className='container d-flex'>

<div>
<li>  Document delivery type: {data?.documentDeliveryType}</li>
</div>

<div>
<li>  Address : {data?.deliveryAddress}</li>
</div>
</div>



</div>

<div className='sectionItem'>

<header>
MRV FEE PAYMENTS
</header>
<div className='container d-flex'>

<div>
<li>  {data?.MRV_Name}</li>


<li>  DS-160 Confirmation Number <br/>
{}
<Barcode value={data?.DS_160_ConfirmationNumber} width={1} height={100} displayValue={false}/>
</li>
</div>

<div>
<li>  {data?.MRV_Name}</li>

<li>  UID <br/>
<Barcode value={data?.UID} width={1} height={
  100} displayValue={false}/>
</li>
</div>

</div>


<h5 className='text-center'><b>{data?.UID}</b></h5>



</div>












</div>
            </div>

    )
}
export default AppointmentConfirmation;