
import React from 'react';
import { MDBListGroup, MDBBtn } from 'mdb-react-ui-kit';
import { Home, User, DollarSign, Calendar, Power } from 'react-feather';
import {useNavigate} from 'react-router-dom'
import CustomAvatar from '../utils/customAvatar';
const sideBarsList = [
  {
    name: "Dashboard",
    link: "/Dashboard",
    icon: <Home />,
    onClick: () => {
      console.log("Dashboard clicked");
      // Add logic to navigate to the dashboard page
    }
  },
  {
    name: "Profile",
    link: "/Profile",
    icon: <User />,
    onClick: () => {
      console.log("Profile clicked");
      // Add logic to navigate to the user's profile page
    }
  },
  {
    name: "Make Payment",
    link: "/Payment",
    icon: <DollarSign />,
    onClick: () => {
      console.log("Make Payment clicked");
      // Add logic to navigate to the payment page
    }
  },
  {
    name: "Appointments",
    link: "/Appointments",
    icon: <Calendar />,
    onClick: () => {
      console.log("Appointments clicked");
      // Add logic to navigate to the user's appointments page
    }
  },
  {
    name: "Log Out",
    link: undefined,
    icon: <Power/>,
    onClick: () => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload();
      // Add logic to navigate to the user's appointments page
    }
  },
  // Add more buttons as needed
];
function UserSideBarContent({closeMenu}) {
  const navigate=useNavigate();
  return (
    <div>
      <div style={{textAlign:'start'}}>
        <div className='d-flex align-items-center justify-content-start'>
<div style={{paddingLeft:16}} onClick={()=>{
  navigate("/")
}}><CustomAvatar src='/logo.jpg' size={50} alt='Immintegral logo'/></div><b>Immintegral</b>
</div>
</div>
    <MDBListGroup className="side-menu">
      {sideBarsList.map((item, index) => (
        <MDBBtn key={index} onClick={()=>{
         if(item?.link)navigate(item.link)
         if(item.onClick)item.onClick();
          if(closeMenu)closeMenu()
        }}  color='light' rippleColor='dark'>
            <span className="icon">{item.icon}</span>
            <span className="name">{item.name}</span>
            </MDBBtn>

      ))}
    </MDBListGroup>

    </div>
  );
}

export default UserSideBarContent;
