import {MDBBtn} from 'mdb-react-ui-kit'
export default function Animation(){

    return (
    <div style={{textAlign:"center"}}>
<img src='/images/flaq.jpg' style={{ maxWidth: "100%", height: "auto", maxHeight: "300px", }} alt='' />

<br/><br/>
<div style={{
      backgroundColor: '#d1ecf1', // Background color for info alert
      color: '#0c5460',           // Text color for info alert
      padding: '10px',            // Padding around the content
      border: '1px solid #bee5eb', // Border color for info alert
      borderRadius: '4px',        // Border radius to round the corners
      marginBottom: '20px'
}}>
     For any complaints or issues, please do not hesitate to contact support.
</div>

<MDBBtn size="large" style={{width:"100%"}} onClick={()=>{
    window.location.href='mailto:support@immintegral.com'
}}>Contact support</MDBBtn>
        </div>
    );
 }