import React, { useState, useEffect, useRef } from 'react';
import "@passageidentity/passage-elements/passage-auth";
import '@passageidentity/passage-elements/passage-profile';
import { toast, Toaster } from 'react-hot-toast';
import useUserDetails from '../../Hooks/userUserDetails';
import { useNavigate } from 'react-router-dom';
import { updateData } from '../../Logics/updateData';
import checkUserFingerPrint from './checkUserFingerPrint';

const BiometricUI = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const [isLogin,setIsLogin]=useState(sessionStorage.isLogin);
  const { user } = useUserDetails();
  const [showVerification, setShowVerification] = useState(false);
  const beforeAuth = (email, biometricData) => {
    // // Check if biometricData is available and meets the criteria for fingerprint authentication
    // if (biometricData && biometricData.type === 'fingerprint') {
    //   return { email }; // Allow authentication only if biometric data is fingerprint
    // } else {
      // Deny authentication for other types of biometric data
    //  console.log("Biometric data other than fingerprint detected.");
      return true;
    
  };
const updateVerified=async ()=>{
  await updateData("Users",user.docId,{...user,verified:true});
  console.log("user updated");
}
  useEffect(() => {
    if (!user) return navigate('/Login');
    if(user?.verified){
navigate("/Verification");
    }
    window.sessionStorage.setItem("verified",true);
if(window.sessionStorage.getItem("verified"))updateVerified();
window.sessionStorage.removeItem("verified");
    const handleAuthSuccess = () => {
      // Redirect to a different page after authentication
      alert("Verification success");
    };
    const { current } = ref;
    if (current) {
      current.addEventListener('auth-success', handleAuthSuccess);
      return () => {
        current.removeEventListener('auth-success', handleAuthSuccess);
      };
    }
  }, [user, navigate]);


  useEffect(()=>{
    //checkUserFingerPrint();
  //  console.log(process.env.REACT_APP_PASSAGE_APP_ID)
  },[])
  return (
    <div className="verifyUI d-flex align-items-center justify-content-center">
      <Toaster />

      {showVerification && <div className='auth-container-ctn d-flex align-items-center justify-content-center close' 
      style={{ position: "fixed",padding:10, bottom: "0", right: "0", zIndex: "99", width: "100%", height: "100%", background: "rgba(0,0,0,0.4)", padding: 20 }} onClick={(el) => {
        const e = el.target;
        if (e && e.classList.contains("close")) {
          setShowVerification(false);
        }
      }} >
       {isLogin  ? <><passage-profile app-id={process.env.REACT_APP_PASSAGE_APP_ID}>
       <passage-passkey-table app-id={process.env.REACT_APP_PASSAGE_APP_ID}></passage-passkey-table>
      </passage-profile></>
: <><passage-auth ref={ref} app-id={process.env.REACT_APP_PASSAGE_APP_ID} beforeAuth={beforeAuth}></passage-auth></>}
      </div>}
      <div className='box' style={{ maxWidth: 100, minHeight: 100, padding: 20, background: 'white' }}>
        <div className='text-center'>
        <h5 className='text-center'> Authenticate your account</h5><br/>
      <div className='text-center'><img src='/images/sensor.jpg' style={{ width: 150, height: 150,margin:"0 auto" }} alt='' /></div>
      </div>

        <div>
  <p className="text-center mt-2">
    Authenticate your account using your device-native biometrics to gain full control of this platform.
  </p>
  
  <button className="btn btn-primary mt-2" onClick={() => {
    window.location.replace("https://immintegral-auth.vercel.app");
  }}>Authenticate Account</button>
</div>


      </div>
    </div>
  );
}

export default BiometricUI;
