import { MDBBadge, MDBIcon } from 'mdb-react-ui-kit';
import CustomAvatar from '../utils/customAvatar';
import {useEffect, useState} from 'react';
import {Menu} from 'react-feather';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn
  } from 'mdb-react-ui-kit';
import Notifications from './notifications';
import { getCurrentDateTime } from '../Logics/date';
import { getAllDoc } from '../Logics/getAllDoc';
import { docQr } from '../Logics/docQr';
import { updateData } from '../Logics/updateData';
import {useNavigate} from 'react-router-dom';

export default function UserNav({openMenu,user}) {
    const [openNotifications,setOpenNotifications]=useState(false);
    const navigate=useNavigate()
    const [notificationLen,setNotificationLen]=useState();
const getNotification=async()=>{
  const notifications=await docQr("Notifications",{
    max:800,
    whereClauses:[
        {
            field:"uid",
            operator:"==",
            value:user.uid
        }
    ]
})
//console.log(notifications);
const unread=notifications.filter((e)=>!e?.isRead);
setNotificationLen(unread.length);
Promise.all(unread.map(async (notification)=>{
await updateData("Notifications",notification.docId,{...notification,isRead:true});
}));
console.log('updated data');
}
    
useEffect(()=>{
 getNotification(); 
},[]);

useEffect(()=>{
console.log(notificationLen)
},[notificationLen])
    return (
        
<>
      <MDBModal open={openNotifications} setOpen={setOpenNotifications} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Notifications</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={()=>setOpenNotifications(!openNotifications)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <Notifications/>
</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={()=>setOpenNotifications(false)}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

        <div className='userNav d-flex align-items-center justify-content-between'>

            {window.innerWidth > 700  ? <div className='greetingUser text-start'>
                <b>{user?.fullname || user.username}</b><br />
                <span>{getCurrentDateTime()}</span>
            </div> : <div style={{textAlign:'start',padding:"18px"}}>
                <Menu onClick={()=>{
                    openMenu()
                }}/>
{/* <img src='/logo.jpg' width={50} height={50} alt='Immintegral logo'/><b>Immintegral</b> */}
</div>}

            <div className='d-flex align-items-center'>

                <a onClick={()=>{
                    setOpenNotifications(true);
                    setNotificationLen(0)
                }} className='mx-3' href='#!'>
                    <MDBIcon fas style={{color:"var(--color)"}} icon='bell' size='lg' />
                    {notificationLen !== 0 && <MDBBadge color='danger' style={{ margin: "-5px" }} notification pill>
                    {notificationLen}
                    </MDBBadge>}
                </a>
<span style={{cursor:"pointer"}} onClick={()=>{
  navigate("/Profile")
}}> 
                <CustomAvatar src={user?.biometricData?.passport || '/images/user.png'} alt="User Avatar" />
                </span>

            </div>


        </div>

        </>
    )
}