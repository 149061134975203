
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';
import { Skeleton } from '@mui/material';
import { getTimeAgoString } from '../../Logics/date';


function Card({ appointment,onBook ,onShowEmbassy}) {
    const { appointmentId, type, dateTime, status, notes,embassy_id,createdAt,forms
    } = appointment;
  //console.log(appointment);
    return (
      <MDBCard className="h-100">
        <MDBCardBody>
          <MDBCardTitle className='d-flex justify-content-between  ' style={{flexFlow:"row wrap"}}><span>Appointment ID:</span> {appointmentId}
          </MDBCardTitle>
          <MDBCardText>Type: {type}</MDBCardText>
          <MDBCardText>Date and Time: {dateTime}</MDBCardText>
          <MDBCardText>Status: {status}</MDBCardText>
          <MDBCardText>Notes: {notes}</MDBCardText>
          <MDBCardText>Updated : {getTimeAgoString(createdAt)}</MDBCardText>
       <div className='d-flex align-items-center justify-content-between'>  
       {onBook && <MDBBtn size='lg' rounded color='dark' onClick={()=>onBook(forms,appointment)}>Book</MDBBtn>}
        {onShowEmbassy && <MDBBtn onClick={()=>onShowEmbassy(embassy_id)} color='tertiary'>Embassy details</MDBBtn>}
</div>

        </MDBCardBody>
      </MDBCard>
    );
}

export const CardSkeleton=()=>{
    return (<>
    <Skeleton variant="text" width={200} height={30} />
    <Skeleton variant="text" width={150} height={20} />
    <Skeleton variant="text" width={200} height={20} />
    <Skeleton variant="text" width={150} height={20} />
    <Skeleton variant="text" width={150} height={20} />
    <Skeleton variant="text" width={200} height={20} />
  </>);
}

  export default Card;